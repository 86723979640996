import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import 'tailwindcss/tailwind.css';
import { router } from './Router';
import { RouterProvider } from 'react-router-dom';
// import App from './App';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const analytics = Analytics({
  app: 'Dashboard',
  plugins: [
    googleAnalytics({
      measurementIds: [process.env.REACT_APP_GOOGLE_ANALYTICS_ID]
    })
  ]
});

analytics.page();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RouterProvider router={router} />
);

