import { atom } from "jotai";
import { ChannelType, PaginatedResponseType } from "../../globals/types";

export const mockupsAtom = atom<PaginatedResponseType<IMockup>>({ results: [], count: 0, hasNext: false, hasBefore: false, channel: ChannelType.WALLMATES });
export const mockupsLoadingAtom = atom<boolean>(false);

export const hasMoreMockupsAtom = atom<boolean>(true);

export const previewAtom = atom<{ [key: number]: IGeneratedPreview}>({});

export const previewLoadingAtom = atom<boolean>(false);

export interface IMockup {
    id?: number;
    s3_path: string;
    thumbnail: string;
    mockup_name: string;
    room_type_id: number;
    style_type_id: number;
    color_type_id: number;
    template_width: number;
    anchor_point: number;
    verified?: boolean;
    premium: boolean;
    flagged: boolean;
}

export interface IMockupsFilter {
    style_type_id?: string;
    color_type_id?: string;
    room_type_id?: string;
    verified?: boolean;
    query_string?: string;
    flagged?: boolean;
}

export interface IGetMockupsRequest {
    page_count: number;
    page: number;
    filter_by: IMockupsFilter;
}

export interface IGeneratePreviewAttributes {
    pattern_scale?: string | number;
    shadow_opacity?: string | number;
    enhance_shadows?: boolean;
}

export interface IFetchGeneratePreviewRequestBody {
    mockup_id: number;
    product_id: number;
    attributes?: IGeneratePreviewAttributes;
    regenerate?: boolean;
}

export interface IGeneratedPreview {
    mockup_id: number;
    product_id: number;
    attributes: IGeneratePreviewAttributes;
    preview_url?: string;
}