import axios, { AxiosResponse } from "axios";
import { environment } from "../../environment";
import React from 'react';
import { useAtom } from "jotai";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { IGetProductsResponse, shopifyProductsLoadingAtom } from "./types";

type SetShopifyProductType = (payload: IGetProductsResponse[]) => void;

type GetShopifyProductsType = (cursor: string | null, direction: 'after' | 'before', query: string | null, pageCount: number, brand_id: number) => Promise<IGetProductsResponse[] | null>;

export type ShopifyProductKeyFunctions = {
    get: GetShopifyProductsType
}

type UseShopifyProducts = [
    IGetProductsResponse[] | undefined,
    SetShopifyProductType,
    ShopifyProductKeyFunctions
]

export const useShopifyProducts = (): UseShopifyProducts => {
    const [product, setProducts] = React.useState<IGetProductsResponse[]>();
    const [, setLoading] = useAtom(shopifyProductsLoadingAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();

    const getShopifyProducts: GetShopifyProductsType = async (cursor: string | null, direction: 'after' | 'before', query: string | null, pageCount: number, brand_id: number): Promise<IGetProductsResponse[] | null> => {
        setLoading(true);

        const response: AxiosResponse<IGetProductsResponse[]> | void = await axios.post(`${environment.productsUrl}/shopify-products`, { 
            direction, 
            cursor, 
            query, 
            pageCount, 
            brand_id 
        }, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (response) {
            const products = response.data;
            setProducts(products);
            setLoading(false);
            return products;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while getting shopify products. Please try again later.'
            })
            setProducts([{hasNext: false, hasBefore: false, count: 0, results: []}]);
            setLoading(false);
            return null;
        }

    }

    const keyFunctions: ShopifyProductKeyFunctions = {
        get: getShopifyProducts
    }

    return [
        product,
        setProducts,
        keyFunctions
    ]
}