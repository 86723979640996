import { useAtom } from "jotai";
import Axios, { AxiosResponse } from 'axios';
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useNavigate } from "react-router-dom";
import {
    CompleteMultipartUploadOutput,
    CreateMultipartUploadOutput, GetObjectOutput,
    HeadObjectOutput,
    PutObjectOutput,
    UploadPartOutput,
} from "aws-sdk/clients/s3";
import parser from 'html-react-parser';
import { 
    IUser, 
    ILoginRequest, 
    IAWSPresignerRequest, 
    IAWSPresignedURL, 
    ISendPresignedRequest, 
    loggedInUserAtom, 
    authTokenAtom, 
    ILoginResponse 
} from "./types";

type LoggedInUser = IUser | null;
type AuthToken = string | null;
type Login = (body: ILoginRequest) => Promise<void>;
type Logout = () => void;
type Presigner = (body: IAWSPresignerRequest) => Promise<IAWSPresignedURL | null>;
type SendPresigned = (req: ISendPresignedRequest) => Promise<CreateMultipartUploadOutput | UploadPartOutput | CompleteMultipartUploadOutput | PutObjectOutput | HeadObjectOutput | null>;

export type UseLoginKeyFunctions = {
    login: Login,
    logout: Logout,
    presigner: Presigner,
    sendPresigned: SendPresigned
}


export type UseLogin = [
    LoggedInUser,
    AuthToken,
    UseLoginKeyFunctions
]

export const useLogin = (): UseLogin => {

    const [,setSnackbar] = useSnackbar();
    const navigate = useNavigate();
    
    const [user, setUser] = useAtom(loggedInUserAtom);
    const [authToken, setAuthToken] = useAtom(authTokenAtom);

    const login: Login = async (body: ILoginRequest) => {
        const result: AxiosResponse<ILoginResponse> | void = await Axios.post(`${environment.authenticationUrl}/login`, body).catch((err: any) => errorCatcher(err, logout));

        if (result) {
            const data: ILoginResponse = (result as AxiosResponse<ILoginResponse>).data;
            if (!data.user) {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'warn',
                    text: 'Login Failed. Please ensure your username and password were entered correctly.'
                });
            } else {
                localStorage.setItem('user', JSON.stringify(data.user))
                localStorage.setItem('authToken', data.authToken as string);
                setUser(data.user);
                setAuthToken(data.authToken);
            }
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Login Failed. Please ensure your username and password were entered correctly.'
            });
        }
        return;
    }

    const logout: Logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('authToken');
        setUser(null);
        setAuthToken(null);
        navigate('/login');
    }

    const presigner: Presigner = async (body: IAWSPresignerRequest): Promise<IAWSPresignedURL | null> => {
        const result: AxiosResponse<IAWSPresignedURL> | void = await Axios.post(`${environment.loginUrl}/aws-presigner`, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, logout));

        if (result) {
            const data: IAWSPresignedURL = (result as AxiosResponse<IAWSPresignedURL>).data;
            return data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'Login Failed'
            });
        }
        return null;
    }

    const sendPresigned = async (body: ISendPresignedRequest): Promise<CreateMultipartUploadOutput | UploadPartOutput | CompleteMultipartUploadOutput | PutObjectOutput | HeadObjectOutput | GetObjectOutput | null> => {
        if (body.type === 'completeMultipart') {
            const result: AxiosResponse<CompleteMultipartUploadOutput> | void = await Axios.post(`${environment.loginUrl}/aws-presigner`, body, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                return result.data;
            }
            return null;
        } else if (body.type === 'createMultipart') {
            const result: AxiosResponse<CreateMultipartUploadOutput> | void = await Axios.post(body.url).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                const output: (React.JSX.Element | string)[] = parser(result.data as string) as (React.JSX.Element | string)[];
                let uploadId = '';
                output.forEach((value: string | React.JSX.Element) => {
                    if (typeof value !== 'string' && value.props.children && value.props.children.length === 3) {
                        if (typeof value.props.children[2].props.children === 'string') {
                            uploadId = value.props.children[2].props.children;
                        }
                    }
                })
                return {
                    UploadId: uploadId
                };
            }
            return null;
        } else if (body.type === 'headObject') {
            const result: AxiosResponse<HeadObjectOutput> | void = await Axios.head(body.url).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                return result.status as any;
            }
            return null;
        } else if (body.type === 'putObject') {
            const result: AxiosResponse<PutObjectOutput> | void = await Axios.put(body.url, body.body, {
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            }).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                return result.data;
            }
            return null;
        } else if (body.type === 'uploadPart') {
            const result: AxiosResponse<UploadPartOutput> | void = await Axios.put(body.url, body.body, {
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            }).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                return { ETag: result.headers.etag.replace(/"/g, '') };
            }
            return null;
        } else if (body.type === 'getObject') {
            const result: AxiosResponse<GetObjectOutput> | void = await Axios.get(body.url).catch((err: any) => errorCatcher(err, logout));

            if (result) {
                return result.data;
            }
            return null;
        }

        return null;
    }

    const loginKeyFunctions: UseLoginKeyFunctions = {
        login,
        logout,
        presigner,
        sendPresigned
    }

    return [
        user,
        authToken,
        loginKeyFunctions
    ]

}