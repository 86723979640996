import { useAtom, useSetAtom } from "jotai";
import Axios, {AxiosResponse} from "axios";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { IBrand } from "../brand";
import { 
  IChecklistItem, 
  ICustomerChecklistCompletionMap, 
  IAuditError, 
  checklistItemsAtom, 
  completionMapsAtom, 
  completedListAtom, 
  checklistItemsLoadingAtom, 
  completionMapsLoadingAtom, 
  completedListLoadingAtom, 
  completedPercentageAtom, 
  auditErrorsAtom, 
  auditCompletedPercentageAtom, 
  auditingAtom, 
  ICompletion, 
  ICompletionMetadata 
} from "./types";


type GetChecklistItemsType = () => Promise<IChecklistItem[] | null>;
type DeleteChecklistItemType = (id: IChecklistItem["id"], archive?: boolean) => Promise<boolean>;
type AddChecklistItemType = (data: IChecklistItem) => Promise<IChecklistItem | null>;
type UpdateChecklistItemType = (data: IChecklistItem | IChecklistItem[]) => Promise<IChecklistItem | null>;
type GetCompletionMapsType = (id: IBrand["id"]) => Promise<ICustomerChecklistCompletionMap[] | []>;
type GetCompletedListType = (id: IBrand["id"]) => Promise<IChecklistItem[] | [] | null>;
type SetChecklistItemsType = (items: IChecklistItem[]) => void;
type SetCompletionMapsType = (maps: ICustomerChecklistCompletionMap[]) => void;
type AddCompletionMapItemType = (item: IChecklistItem, brand_id: IBrand["id"]) => Promise<void>;
type CheckCompletionType = (item: IChecklistItem, brand_id: IBrand["id"]) => Promise<IAuditError | void>;
type AuditChecklistItemsType = (id: IBrand["id"], generateMapRecords?: boolean, verbose?: boolean) => Promise<IAuditError[] | []>;
type HandleCloseAuditType = () => void;
type CheckPageVisitsType = (pathName: Location['pathname'], brand_id: IBrand["id"]) => void;

type CustomerChecklistFunctions = {
  getChecklistItems: GetChecklistItemsType;
  deleteChecklistItem: DeleteChecklistItemType;
  addChecklistItem: AddChecklistItemType;
  updateChecklistItem: UpdateChecklistItemType;
  getCompletionMaps: GetCompletionMapsType;
  getCompletedList: GetCompletedListType;
  setChecklistItems: SetChecklistItemsType;
  setCompletionMaps: SetCompletionMapsType;
  addCompletionMapItem: AddCompletionMapItemType;
  checkCompletion: CheckCompletionType;
  auditChecklistItems: AuditChecklistItemsType;
  handleCloseAudit: HandleCloseAuditType;
  checkPageVisits: CheckPageVisitsType;
}

type UseChecklist = [
  IChecklistItem[],
  ICustomerChecklistCompletionMap[],
  CustomerChecklistFunctions
];

/**
 * Customer Checklist Hook
 * @returns {UseChecklist}
 * @example:
 * const [checklistItems, customerChecklistCompletionMaps, checklistItemFunctions] = useCustomerChecklist();
 * await checklistItemFunctions.getChecklistItems();
 * await checklistItemFunctions.deleteChecklistItem(id);
 * await checklistItemFunctions.addChecklistItem(data);
 * await checklistItemFunctions.updateChecklistItem(data);
 * await checklistItemFunctions.getCompletionMaps(id);
 * await checklistItemFunctions.getCompletedList(id);
 * checklistItemFunctions.setChecklistItems(items);
 * await checklistItemFunctions.addCompletionMapItem(item_id, brand_id);
 * await checklistItemFunctions.checkCompletion(item, brand_id);
 * await checklistItemFunctions.auditChecklistItems(id);
 * checklistItemFunctions.handleCloseAudit();
 */
export const useCustomerChecklist = (): UseChecklist => {
  const [checklistItems, setChecklistItems] = useAtom(checklistItemsAtom);
  const [completionMaps, setCompletionMaps] = useAtom(completionMapsAtom);
  const setCompletedList = useSetAtom(completedListAtom);
  const setChecklistItemsLoading = useSetAtom(checklistItemsLoadingAtom);
  const setCompletionMapsLoading = useSetAtom(completionMapsLoadingAtom);
  const setCompletedListLoading = useSetAtom(completedListLoadingAtom);
  const setCompletedPercentage = useSetAtom(completedPercentageAtom);
  const setAuditErrors = useSetAtom(auditErrorsAtom);
  const setAuditPercentage = useSetAtom(auditCompletedPercentageAtom);
  const setAuditing = useSetAtom(auditingAtom);
  const [, setSnackbar] = useSnackbar();
  const [, authToken, loginFunctions] = useLogin();

  const getChecklistItems = async (): Promise<IChecklistItem[] | null> => {
    setCompletionMapsLoading(true);
    const items: AxiosResponse<IChecklistItem[]> | void = await Axios.get(`${environment.checklistUrl}/items`, {
      headers: {
        'X-Wallmates-Auth': authToken
      }
    }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

    if (items) {
      setChecklistItems(items.data);
      setChecklistItemsLoading(false);
      return items.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'Unknown Error: Failed to get checklist items. If the problem persists, please contact support',
      });
      setChecklistItems([]);
      setChecklistItemsLoading(false);
      return [];
    }
  }

  const addChecklistItem = async (data: IChecklistItem): Promise<IChecklistItem | null> => {
    const insertResponse: AxiosResponse<IChecklistItem> | void = await Axios.post(`${environment.checklistUrl}/items`, data, {
      headers: {
        'X-Wallmates-Auth': authToken
      }
    }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

    if (insertResponse) {
      await getChecklistItems();
      setSnackbar({
        show: true,
        snackbarLevel: 'info',
        text: 'Checklist Item added Successfully.'
      });
      return insertResponse.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an issue while adding a checklist item. Please try again later.'
      });
      return null;
    }
  }

  const updateChecklistItem = async (data: IChecklistItem | IChecklistItem[]): Promise<IChecklistItem | null> => {
    const updateResponse: AxiosResponse<IChecklistItem> | void = await Axios.put(`${environment.checklistUrl}/items`, data, {
      headers: {
        'X-Wallmates-Auth': authToken
      }
    }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

    if (updateResponse) {
      await getChecklistItems();
      setSnackbar({
        show: true,
        snackbarLevel: 'info',
        text: 'Checklist Item updated Successfully.'
      });
      return updateResponse.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an issue while updating a checklist item. Please try again later.'
      });
      return null;
    }
  }

  const deleteChecklistItem = async (id: IChecklistItem["id"], archive = true): Promise<boolean> => {
    const deleteResponse: AxiosResponse<boolean> | void = await Axios.delete(`${environment.checklistUrl}/items?id=${id}&archive=${archive}`, {
      headers: {
        'X-Wallmates-Auth': authToken
      }
    }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

    if (deleteResponse) {
      return deleteResponse.data;
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an issue while deleting a checklist item. Please try again later.'
      });
      return false;
    }
  }

  const getCompletionMaps = async (id: IBrand["id"]): Promise<ICustomerChecklistCompletionMap[] | []> => {
    setCompletionMapsLoading(true);
    const items: AxiosResponse<ICustomerChecklistCompletionMap[]> | void = await Axios.get(`${environment.checklistUrl}/maps?brand_id=${id}`, {
      headers: {
        'X-Wallmates-Auth': authToken
      }
    }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

    if (items?.data?.length) {
      setCompletionMaps(items.data);
      setCompletionMapsLoading(false);
      return items.data;
    } else {
      setCompletionMaps([]);
      setCompletionMapsLoading(false);
      return [];
    }
  }

  const getCompletedList = async (id: IBrand["id"]): Promise<IChecklistItem[] | [] | null> => {
    setCompletedListLoading(true);
    const maps = await getCompletionMaps(id);
    const items = await getChecklistItems();

    if (items?.length) {
      const completedListArr: IChecklistItem[] = [];
      const totalItems = items.length;
      let completedItems = 0;
      items?.forEach((item) => {
        const map = maps?.find((map) => map.completed_item_id === item.id);
        item.completed = !!(map || item.completion_type === ICompletion.AUTOMATIC);
        if (item.completed) {
          completedItems++;
        }
        completedListArr.push(item);
      })
      const result = completedListArr
        .sort((item) => item.rank ? -1 : 1)
        .sort((item) => item.completed ? 1 : -1)
      setCompletedList(result);
      setCompletedPercentage((completedItems / totalItems) * 100);
      setCompletedListLoading(false);
      return result;
    }
    setCompletedListLoading(false);
    return [];
  }

  /**
   * Add a completion map item for a given checklist item id
   * @param {IChecklistItem} item
   * @param {string | number} brand_id
   */
  const addCompletionMapItem = async (item: IChecklistItem, brand_id: IBrand["id"]): Promise<void> => {
    if (!item?.id || !brand_id) {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'Invalid Checklist Item or Brand ID.'
      });
      return;
    }
    const map: ICustomerChecklistCompletionMap = {
      brand_id,
      completed_item_id: item.id
    }
    const insertResponse: AxiosResponse<ICustomerChecklistCompletionMap> | void = await Axios.post(`${environment.checklistUrl}/maps`, map, {
      headers: {
        'X-Wallmates-Auth': authToken
      }
    }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

    if (insertResponse?.data) {
      setSnackbar({
        show: true,
        snackbarLevel: 'info',
        text: 'Checklist Item marked as completed.'
      });
    } else {
      setSnackbar({
        show: true,
        snackbarLevel: 'error',
        text: 'There was an issue while marking a checklist item as completed. Please try again later.'
      });
    }
  }

  const checkCompletion = async (item: IChecklistItem, brand_id: IBrand["id"], generateMapRecords = true): Promise<IAuditError | void> => {
    if (!item?.id || !brand_id) {
      return {
        message: 'Invalid Checklist Item ID or Brand ID',
        item
      };
    }
    if (item.completed) return;
    let metadataStr = item?.completion_metadata?.toString();
    let metadata: ICompletionMetadata;
    let error;
    let key: string;

    try {
      const regex = new RegExp('{(.*?)}', 'g');
      const matches = metadataStr?.match?.(regex);
      if (matches?.length) {
        matches?.forEach((match: string) => {
          if (match?.includes('environment.')) {
            key = match?.replace(/environment.|{|}/g, '');
            metadataStr = metadataStr?.replace?.(match, environment[key as keyof typeof environment] as string);
          } else {
            key = match?.replace?.(/[{}]/g, '');
            metadataStr =  metadataStr?.replace?.(match, eval(key));
          }
        });
      }
      metadata = JSON.parse(metadataStr || '{}');
    } catch (e: any) {
      return {
        message: e?.message || e || 'Invalid Metadata Object',
        item
      };
    }
    if (!metadata) {
      return {
        message: 'Invalid Metadata Object',
        item
      }
    }

    if (item.completion_type === ICompletion.LOCAL_STORAGE) {
      if (!metadata?.key || !metadata?.columns || !metadata?.values) {
        return {
          message: 'Invalid Local Storage Item',
          item
        };
      } else if (metadata?.columns?.length !== metadata?.values?.length) {
        return {
          message: 'Local Storage Metadata Columns and Values Mismatch',
          item
        };
      } else if (metadata?.columns?.length && metadata?.values?.length) {
        const storage = localStorage.getItem(metadata.key);
        if (storage) {
          const data = JSON.parse(storage);
          if (metadata.columns.every((col, i) => data[col] === metadata.values?.[i])) {
            if (generateMapRecords) await addCompletionMapItem(item, brand_id);
            return;
          } else {
            return {
              message: 'Local Storage Check Failed',
              item
            };
          }
        } else {
          return {
            message: 'Local Storage Check Failed, No Data Found',
            item
          };
        }
      }
    } else if (item.completion_type === ICompletion.PAGE_VISIT) {
      // check if the page has been visited handled by the checkPageVisits function
      return {
        message: 'This page has not been visited by a brand user',
        item
      };
    } else if (item.completion_type === ICompletion.MANUAL) {
      return {
        message: 'Manual Completion Required',
        item
      };
    } else if (item.completion_type === ICompletion.AUTOMATIC) {
      if(generateMapRecords) await addCompletionMapItem(item, brand_id);
      return;
    } else if (item.completion_type === ICompletion.COLUMN_CHECK || item.completion_type === ICompletion.ROW_CHECK) {
      const response = await Axios.request({
        url: metadata.url,
        method: metadata.method || 'get',
        headers: metadata.headers || {
          'X-Wallmates-Auth': authToken
        },
        data: metadata.data || null
      }).catch((err: any) => errorCatcher(err, loginFunctions.logout)) as any;

      switch (response?.status) {
        case 404:
          error = {
            message: 'API Call Failed: Url Not Found',
            item
          };
          break;
        case 500:
          error = {
            message: 'API Call Failed: Internal Server Error',
            item
          };
          break;
        default:

          break;
      }

      if (response?.data) {
        const {columns, values, status} = metadata || {};
        switch (item.completion_type) {
          case ICompletion.ROW_CHECK:
            if (!Number.isNaN(status) && response?.status !== status) {
              return {
                message: 'Row Check Failed',
                item
              };
            }
            if (generateMapRecords) await addCompletionMapItem(item, brand_id);
            return;
          case ICompletion.COLUMN_CHECK:

            if (columns?.length && values?.length && columns?.length !== values?.length) {
              return {
                message: 'Expected values and check columns length mismatch',
                item
              };
            } else if (columns?.length && values?.length) {
              // compare the returned data with the expected values array
              if (columns.every((col, i) => (response?.data[col] === values[i]) || values[i] === '*')) {
                if (generateMapRecords) await addCompletionMapItem(item, brand_id);
                return;
              } else {
                return {
                  message: 'Column Check Failed',
                  item
                };
              }
            } else if (columns?.length && !values?.length) {
              return {
                message: 'Column Check Failed, missing expected values',
                item
              };
            }
            break;
        }
      } else {
        return {
          message: 'Data missing in API Response',
          item
        };
      }
    } else if (item.completion_type === ICompletion.PRESIGNED_URL) {
      const presigned = await loginFunctions.presigner({
        bucket: metadata.bucket || '',
        key: metadata.key || '',
        type: 'headObject'
      });

      const response = await Axios.head<Promise<AxiosResponse<Headers>>>(presigned?.url || '', {})
        .catch((err: any) => errorCatcher(err, loginFunctions.logout));

      switch (response?.status) {
        case 200:
            if (generateMapRecords) await addCompletionMapItem(item, brand_id);
            break;
        case 404:
          error = {
            message: 'API Call Failed: Url Not Found',
            item
          };
          break;
        case 500:
          error = {
            message: 'API Call Failed: Internal Server Error',
            item
          };
          break;
        default:
          break;
      }
    }

    return error;
  }

  /**
   *
   * @param {IBrand["id"]} id
   * @param {boolean} generateMapRecords
   * @param {boolean} verbose Whether to show the snackbar messages or not
   */
  const auditChecklistItems = async (id: IBrand["id"], generateMapRecords = true, verbose = true): Promise<IAuditError[] | []> => {
    setAuditing(true);
    const items = await getCompletedList(id);
    const errors: IAuditError[] = [];
    let itemsCompleted = 0;

    if (items?.length) {
      for (const item of items) {
        const error = await checkCompletion(item, id, generateMapRecords);
        if (error) {
          errors.push(error);
        }
        itemsCompleted++;
        setAuditPercentage((itemsCompleted / items?.length) * 100);
      }
    } else {
      if (verbose) {
        setSnackbar({
          show: true,
          snackbarLevel: 'error',
          text: 'There was an issue fetching items while auditing the checklist. Add items or try again later.'
        });
      }
    }

    if (verbose) {
      if (!errors.length) {
        setSnackbar({
          show: true,
          snackbarLevel: 'info',
          text: 'Checklist Audit Completed Successfully. Brand ready for fulfillment!'
        });
      } else {
        setSnackbar({
          show: true,
          snackbarLevel: 'error',
          duration: 10,
          text: 'Checklist Audit Completed with Issues. Please review and correct the issues and try again.'
        });
      }
    }
    setAuditErrors(errors);
    setAuditPercentage(100);
    return errors;
  }

  const handleCloseAudit = () => {
    setAuditing(false);
    setTimeout(() => {
      setAuditPercentage(0);
      setAuditPercentage(0);
    }, 500);
  }

  const checkPageVisits = async (pathName: Location['pathname'], brand_id: IBrand["id"]) => {
    const items = await getCompletedList(brand_id);
    const item = items?.find((item) => item.link === pathName && item.completion_type === ICompletion.PAGE_VISIT && !item.completed);
    if (item) {
      await addCompletionMapItem(item, brand_id);
    }
  }

  const customerChecklistFunctions: CustomerChecklistFunctions = {
    getChecklistItems,
    deleteChecklistItem,
    addChecklistItem,
    updateChecklistItem,
    getCompletionMaps,
    getCompletedList,
    setChecklistItems,
    setCompletionMaps,
    addCompletionMapItem,
    checkCompletion,
    auditChecklistItems,
    handleCloseAudit,
    checkPageVisits,
  }


  return [
    checklistItems,
    completionMaps,
    customerChecklistFunctions,
  ]
}
