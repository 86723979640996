import React from 'react';
import { Button, Field, Input, Label, Spinner, Text } from "../../components/UI";
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../hooks';
import { useUsers, IForgotPasswordRequest } from '../../services';

const imagesBucket = process.env.REACT_APP_AWS_BUCKET_IMAGES || '';
const imageLink = `https://${imagesBucket}.s3.us-west-1.amazonaws.com/dashboard-images/Wallmates-Log-In.png`;

const ForgotPassword: React.FunctionComponent<{}> = (): JSX.Element => {

    const [,,AuthenticationFunctions] = useUsers();
    const [showSpinner, setShowSpinner] = React.useState(false);

    const navigate = useNavigate();

    const form = useForm([
        {
            key: 'email',
            validation: (value: string) => {
                if (value == null) return 'Must provide a value';
                return null;
            }
        },
    ]);

    const submitForm = async () => {
        setShowSpinner(true);      

        const addValue: IForgotPasswordRequest = {
            email: form.valueAtKey('email'),
            send_reset_email: true
        }

        await AuthenticationFunctions.forgotPassword(addValue);
        setShowSpinner(false);
    }

    return(
        <>
            <div className="w-screen h-screen relative">
                <div className="absolute t-0 l-0 w-full h-full z-0">
                    <img 
                        src={imageLink}
                        className="w-full h-full"
                    />
                </div>
                <div className="absolute t-0 l-0 w-full h-full z-20 bg-black/30"></div>
                <div className="absolute t-0 l-0 w-full h-full z-40">
                    <div className="flex flex-row justify-center items-center w-full h-full">
                        <div className="w-full md:w-1/2 lg:w-2/3 xl:w-1/3 h-full md:h-auto lg:h-auto bg-slate-50 dark:bg-slate-800 shadow-lg border-2 border-slate-400 rounded-lg min-w-64">
                            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                                <h1 className='text-4xl text-gray-900 dark:text-white mb-5 text-center'>Forgot Password</h1>
                                <p className="text-gray-900 dark:text-white mb-10">Please enter your email to recover your password.</p>
                                <Field>
                                    <Label>Email</Label>
                                    <Input type='text' onChange={(ev: React.ChangeEvent<HTMLInputElement>) => form.setValueAtKey('email', ev.target.value)}></Input>
                                </Field>
                                <div className="flex flex-row justify-between">
                                    <Text className="!font-bold pt-8 cursor-pointer" onClick={() => navigate('/login')}>Back To Login</Text>
                                    <div className="flex justify-end border-stone-700 pt-6 gap-x-4">
                                        <Spinner show={showSpinner}></Spinner>
                                        <Button onClick={ submitForm } color="emerald">Submit</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;