import React, { Fragment } from "react";
import { ChildNavigationItem, DesktopDashboardProps, NavigationItem } from "../Dashboard.types";
import { ArrowLeftEndOnRectangleIcon, BellIcon, ChevronDownIcon, ChevronLeftIcon, CogIcon, LanguageIcon, UserCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Link, Text, Translate, Flyout } from "../../UI";
import { RouteConstants } from "../../../Routes";
import { useLocation } from "react-router-dom";
import { IPermission, useLogin } from "../../../services";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

export const Navigator: React.FunctionComponent<DesktopDashboardProps> = (props: DesktopDashboardProps): JSX.Element => {
    return (
        <>
            {/* Static sidebar for desktop */}
            <div className="
                hidden z-10 lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col
            ">
                <Navigation key="desktop-sidebar" {...props} />
            </div>

            <div className="
                lg:hidden z-10 fixed inset-y-0 flex w-72 flex-col
            ">
                <Navigation key="mobile-sidebar" {...props} />
            </div>
        </>
    )
}

const NavItemStyles = "flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 dark:text-white text-black dark:hover:bg-stone-800 hover:bg-stone-300 cursor-pointer";

const Navigation: React.FunctionComponent<DesktopDashboardProps> = (props: DesktopDashboardProps): JSX.Element => {
    const { navigation, setNavigation, closeSidebar, setNotificationSlideoverOpen, setShowLanguageDialog } = props;

    const { pathname } = useLocation();
    const [user,,loginKeyFunctions] = useLogin();

    const imagesBucket = process.env.REACT_APP_AWS_BUCKET_IMAGES || '';

    React.useEffect(() => {

        // make the highlighted option remain on refresh
        setNavigation(navigation.map((nav: NavigationItem) => {
            if (nav.href === pathname) {
                nav.current = true;
            } else {
                nav.current = false;
                nav.expanded = false;
            }

            if (nav.children) {
                let foundChildMatch = false;
                nav.children = nav.children.map((child: ChildNavigationItem) => {
                    if (child.href === pathname) {
                        child.current = true;
                        foundChildMatch = true;
                    }

                    return child;
                });
                if (foundChildMatch) {
                    nav.expanded = true;
                    nav.current = true;
                }
            }

            return nav;
        }))
    }, [])

    const getIsNavExpanded = (item: NavigationItem) => {
        return item.current || 
            (item.children && item.children.filter((child: ChildNavigationItem) => child.current).length > 0) ||
            item.expanded;
    }

    const updateCurrentItem = (item: NavigationItem, isCurrent = true) => {
        const nav = navigation.map((i: NavigationItem) => {
            if (i.href === item.href) {
                i.current = isCurrent;
                if (i.children) {
                    i.expanded = true;
                }
            } else if (isCurrent) {
                i.expanded = false;
                i.current = false;
                if (i.children) {
                    i.children = i.children.map((child: ChildNavigationItem) => {
                        child.current = false;
                        return child;
                    })
                }
            }
            return i;
        });
        setNavigation(nav);
        closeSidebar(false);
    }

    const updateCurrentItemChild = (item: NavigationItem, child: ChildNavigationItem) => {
        const nav = navigation.map((i: NavigationItem) => {
            if (i.href === item.href) {
                i.current = true;
                if (i.children) {
                    i.expanded = true;
                    i.children = i.children.map((c: ChildNavigationItem) => {
                        if (c.href === child.href) {
                            c.current = true;
                        } else {
                            c.current = false;
                        }
                        return c;
                    })
                }
            } else {
                i.current = false;
                if (i.children) {
                    i.expanded = false;
                    i.children = i.children.map((c: ChildNavigationItem) => {
                        c.current = false;
                        return c;
                    });
                }
            }
            return i;
        });
        setNavigation(nav);
        closeSidebar(false);
    }

    const logout = () => {
        loginKeyFunctions.logout();
    }

    const navItem = (item: NavigationItem) => {
        return (
            <Fragment key={item.name}>
                <li className="group flex flex-row flex-grow w-full">
                    <Link
                        href={item.href}
                        action={() => {
                            updateCurrentItem(item)
                        }}
                        className={classNames(
                            item.current ? 
                            'dark:bg-stone-800 dark:text-white bg-stone-300 text-black' : 
                            'dark:text-gray-300 dark:group-hover:text-white dark:group-hover:bg-stone-800 text-gray-600 group-hover:text-black group-hover:bg-stone-300',
                            'flex gap-x-3 p-2 text-sm leading-6 font-semibold flex-grow',
                            item.children && item.children.length > 0 ?
                            'rounded-l-md' :
                            'rounded-md',
                            'cursor-pointer'
                        )}
                    >
                        <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                        <Text><Translate translation_key={item.name}></Translate></Text>
                    </Link>
                    {
                        item.children && item.children.length > 0 ?
                        <div 
                            onClick={() => {
                                updateCurrentItem(item)
                            }}
                            className={classNames(
                                item.current
                                ? 'dark:bg-stone-800 dark:text-white bg-stone-300 text-black'
                                : 'dark:text-gray-300 dark:group-hover:text-white dark:group-hover:bg-stone-800 text-gray-600 group-hover:text-black group-hover:bg-stone-300',
                                'rounded-r-md p-2 text-sm leading-6 font-semibold w-10'
                            )}
                        >
                            {
                                getIsNavExpanded(item) ?
                                <ChevronDownIcon className="h-6 w-6 shrink-0 cursor-pointer" /> :
                                <ChevronLeftIcon className="h-6 w-6 shrink-0 cursor-pointer" />
                            }
                        </div> :
                        null
                    }
                </li>
                {
                    item.children && item.children.length > 0 && item.expanded ?
                    item.children.map((child: ChildNavigationItem) => (
                        <li className="pl-6 ml-5 border-l dark:border-gray-400 border-gray-600" key={child.name}>
                            <Link
                                href={child.href}
                                action={() => {
                                    updateCurrentItemChild(item, child)
                                }}
                                className={classNames(
                                    child.current ? 
                                    'dark:bg-stone-800 dark:text-white bg-stone-300 text-black' : 
                                    'dark:text-gray-300 dark:group-hover:text-white dark:group-hover:bg-stone-800 text-gray-600 group-hover:text-black group-hover:bg-stone-300',
                                    'flex gap-x-3 p-2 rounded-md text-sm leading-6 font-semibold flex-grow',
                                    'cursor-pointer'
                                )}
                            >
                                <child.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                <Text><Translate translation_key={child.name}></Translate></Text>
                            </Link>
                        </li>
                    )) : null
                }
            </Fragment>
        )
    }

    return (
        <>
            <div className="flex grow flex-col gap-y-2 dark:bg-stone-900 bg-stone-200 px-6">
                <div key={"company-logo"} className="flex h-32 shrink-0 items-center pt-6">
                    <img 
                        className="w-[60px] h-[60px] block dark:hidden"
                        src={`https://${imagesBucket}.s3.us-west-1.amazonaws.com/dashboard-images/Wallmates-Icon-light-mode.png`}
                    />
                    <img 
                        className="w-[60px] h-[60px] hidden dark:block"
                        src={`https://${imagesBucket}.s3.us-west-1.amazonaws.com/dashboard-images/Wallmates-Icon-dark-mode.png`}
                    />
                </div>
                <nav key={"navigation-main"} className="flex flex-1 flex-col">
                    <ul className="flex justify-between flex-col mb-4 h-full">
                        <li key={"main-nav"}>
                            <ul key="main-nav-ul" className="-mx-2 space-y-1">
                                {navigation.map((item: NavigationItem) => {
                                    const userPermission = (user?.permissions?.permission_id as IPermission || undefined)?.permission;
                                    if (!userPermission) {
                                        return null;
                                    }

                                    if (item.permissionLevel === 'USER' && (
                                        userPermission === 'USER' || userPermission === 'ADMIN' || userPermission === 'SUPERUSER' || userPermission === 'SUPERADMIN'
                                    )) {
                                        return navItem(item);
                                    }
                            
                                    if (item.permissionLevel === 'ADMIN' && (
                                        userPermission === 'ADMIN' || userPermission === 'SUPERADMIN'
                                    )) {
                                        return navItem(item);
                                    }
                            
                                    if (item.permissionLevel === 'TECHNICIAN' && (
                                        userPermission === 'TECHNICIAN' || userPermission === 'SUPERUSER' || userPermission === 'SUPERADMIN'
                                    )) {
                                        return navItem(item);
                                    }
                            
                                    if (item.permissionLevel === 'SUPERUSER' && (
                                        userPermission === 'SUPERUSER' || userPermission === 'SUPERADMIN'
                                    )) {
                                        return navItem(item);
                                    }
                            
                                    if (item.permissionLevel === 'EVERYBODY' && (
                                        userPermission === 'ADMIN' || userPermission === 'SUPERUSER' || userPermission === 'TECHNICIAN' || userPermission === 'USER' || userPermission === 'SUPERADMIN'
                                    )) {
                                        return navItem(item);
                                    }
                            
                                    if (item.permissionLevel === 'SUPERADMIN' && (
                                        userPermission === 'SUPERADMIN'
                                    )) {
                                        return navItem(item);
                                    }

                                    return null;
                                })}
                            </ul>
                        </li>
                        <div className="flex flex-col">
                            <li>
                                <Link
                                    href={RouteConstants.CONTACT_US}
                                    className={NavItemStyles + ' pl-3'}
                                >
                                    <QuestionMarkCircleIcon key={"contact-us-icon"}  className="h-6 w-6 shrink-0" />
                                    <Text><Translate translation_key="NAVIGATION.CONTACT_US" /></Text>
                                </Link>
                            </li>

                            <Flyout title={user?.email as string} icon={UserCircleIcon}>
                                <li key="notifications">
                                    <Link
                                        action={() => {
                                            setNotificationSlideoverOpen(true);
                                            closeSidebar(false);
                                        }}
                                        href=""
                                        className={NavItemStyles + ' rounded-t-md'}
                                    >
                                        <BellIcon key={"settings-icon"}  className="h-6 w-6 shrink-0" />
                                        <span aria-hidden="true">
                                        <Translate translation_key="NAVIGATION.NOTIFICATIONS" />
                                    </span>
                                    </Link>
                                </li>
                                <li key="language">
                                    <Link
                                        action={() => {
                                            setShowLanguageDialog(true);
                                            closeSidebar(false);
                                        }}
                                        href=""
                                        className={NavItemStyles}
                                    >
                                        <LanguageIcon key={"language-icon"}  className="h-6 w-6 shrink-0" />
                                        <span aria-hidden="true">
                                        <Translate translation_key="NAVIGATION.LANGUAGE" />
                                    </span>
                                    </Link>
                                </li>
                                {
                                    user && (
                                        (user.permissions?.permission_id as IPermission).permission === 'USER' ||
                                        (user.permissions?.permission_id as IPermission).permission === 'SUPERUSER'  ||
                                        (user.permissions?.permission_id as IPermission).permission === 'TECHNICIAN' ||
                                        (user.permissions?.permission_id as IPermission).permission === 'ADMIN' ||
                                        (user.permissions?.permission_id as IPermission).permission === 'SUPERADMIN'
                                    ) ?
                                        <li key="settings">
                                            <Link
                                                href={RouteConstants.SETTINGS_HOME}
                                                action={() => {
                                                    closeSidebar(false);
                                                }}
                                                className={NavItemStyles}
                                            >

                                                <CogIcon key={"settings-icon"}  className="h-6 w-6 shrink-0" />
                                                <span aria-hidden="true">
                                            <Translate translation_key="NAVIGATION.SETTINGS" />
                                        </span>
                                            </Link>
                                        </li> : null
                                }
                                <li key="logout">
                                    <Link
                                        action={() => {
                                            closeSidebar(false);
                                            logout();
                                        }}
                                        href=""
                                        className={NavItemStyles + ' rounded-b-md'}
                                    >
                                        <ArrowLeftEndOnRectangleIcon key={"logout-icon"} className="h-6 w-6 shrink-0" />
                                        <span aria-hidden="true">
                                        <Translate translation_key="NAVIGATION.LOGOUT" />
                                    </span>
                                    </Link>
                                </li>
                            </Flyout>
                        </div>
                    </ul>
                </nav>
            </div>
        </>
    )
}