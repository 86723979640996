import React from 'react';
import { Button } from "../../components/UI";
import { useUsers } from '../../services';
import { useSearchParams, useNavigate } from 'react-router-dom';

const imagesBucket = process.env.REACT_APP_AWS_BUCKET_IMAGES || '';
const imageLink = `https://${imagesBucket}.s3.us-west-1.amazonaws.com/dashboard-images/Wallmates-Log-In.png`;

const VerifyEmail: React.FunctionComponent<{}> = (): JSX.Element => {

    const [,,AuthenticationFunctions] = useUsers();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        const user_id = parseInt(searchParams.get('user_id') as string);
        const verify_token = searchParams.get('verify_token') as string;
        AuthenticationFunctions.verify({user_id, verify_token});
    }, []);

    const toLogin = async () => {
        navigate('/login');
    }

    return(
        <>
            <div className="w-screen h-screen relative">
                <div className="absolute t-0 l-0 w-full h-full z-0">
                    <img 
                        src={imageLink}
                        className="w-full h-full"
                    />
                </div>
                <div className="absolute t-0 l-0 w-full h-full z-20 bg-black/30"></div>
                <div className="absolute t-0 l-0 w-full h-full z-40">
                    <div className="flex flex-row justify-center items-center w-full h-full">
                        <div className="w-full md:w-1/2 lg:w-2/3 xl:w-1/3 h-full md:h-auto lg:h-auto bg-slate-50 dark:bg-slate-800 shadow-lg border-2 border-slate-400 rounded-lg min-w-64">
                            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                                <div className="text-3xl text-center text-gray-900 dark:text-white mb-3">Email Verification</div>
                                <div className="text-l text-center text-gray-900 dark:text-white mb-10">Your email is now verified. Click the button below to login to Wallmates.</div>
                                <Button onClick={ toLogin } color="emerald">Login</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VerifyEmail;